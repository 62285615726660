<template>
    <div class="mysubscribe">
        <!-- PC端 -->
        <template v-if="isPC">
            <p class="title">订阅管理<i class="iconfont icon-close" @click="$router.push({path: '/'})"></i></p>
            <div class="source-box source-box-selected line-bottom">
                <p>已订阅平台（支持拖拽排序）</p>
                <transition-group name="list-transition" tag="div">
                    <div class="source-item source-item-selected list-transition-item" v-for="(source, i) in order" :key="source.id" :data-index="i" draggable="true" @dragstart="dragStart" @dragenter="dragEnter" @dragend="dragEnd">
                        <span>{{source.name}}</span>
                        <i class="iconfont icon-close" @click="deleteOrder(i, source.id)"></i>
                    </div>
                </transition-group>
                <div class="btn-box" v-if="source[0]">
                    <button class="btn btn-red-full" @click="save">保存</button>
                </div>
            </div>
            <div class="source-box" v-for="(sort, k) in source" :key="k">
                <p>{{sort.name}}</p>
                <div :class="['source-item', `source-item-animation-${item.animation}`]" v-for="(item, i) in source[k].data" :key="'s' + i" @click="add(k, i)">{{item.name}}</div>
            </div>
        </template>
        <!-- 移动端 -->
        <template v-else>
            <div class="source-box" v-for="(sort, k) in source" :key="k">
                <p>{{sort.name}}</p>
                <div :class="{'source-item': true, 'source-item-selected': item.is_selected == 1}" v-for="(item, i) in source[k].data" :key="i" @click="select(k, i)">{{item.name}}</div>
            </div>
            <div class="btn-box" v-if="source[0]">
                <button class="btn btn-red-full" @click="save">保存</button>
            </div>
        </template>
    </div>
</template>

<script>
    Array.prototype.remove = function(val) {
        var index = this.indexOf(val)
        if (index > -1) this.splice(index, 1)
    }
    let start = null;
    let sortObj = {};
    let timer = null
    export default {
        name: 'mysubscribe',
        data() {
            return {
                source: [],
                order: [],
                isPC: true
            }
        },
        methods: {
            // 开始拖动
            dragStart(e) {
                start = e.target.dataset.index
            },
            // 拖动到某一个地方
            dragEnter(e) {
                if (e.target.className == 'source-item source-item-selected list-transition-item') {
                    let end = e.target.dataset.index
                    if (end && start && start !== end) {
                        let preArr = this.order
                        let old = preArr[start]
                        preArr.splice(start, 1)
                        preArr.splice(end, 0, old)
                        this.order = preArr
                        start = end
                    }
                }
            },
            // 结束拖动
            dragEnd() {
                start = null
            },
            // 移动端订阅选择
            select(sortIndex, itemIndex) {
                if (this.source[sortIndex].data[itemIndex].is_selected == 1) {
                    this.source[sortIndex].data[itemIndex].is_selected = 0
                    for (let i = 0; i < this.order.length; i++) {
                        if (this.source[sortIndex].data[itemIndex].id == this.order[i].id) {
                            this.order.splice(i, 1)
                            break
                        }
                    }
                } else {
                    if (this.order.length >= 30) {
                        this.$toast('订阅平台数量不能超过30个', 'warn')
                    } else {
                        this.source[sortIndex].data[itemIndex].is_selected = 1
                        let canPush = true
                        for (let i = 0; i < this.order.length; i++) {
                            if (this.source[sortIndex].data[itemIndex].id == this.order[i].id) {
                                canPush = false
                                break
                            }
                        }
                        if (canPush) this.order.push(this.source[sortIndex].data[itemIndex])
                    }
                }
            },
            // PC端订阅选择
            add(sortIndex, itemIndex) {
                let animation = 0
                if (this.order.length >= 30) {
                    this.$toast('订阅平台数量不能超过30个', 'warn')
                    animation = 1
                } else {
                    if (this.source[sortIndex].data[itemIndex].is_selected == 1) {
                        this.$toast('已订阅该平台', 'warn')
                        animation = 1
                    } else {
                        this.$toast('添加成功，请手动保存')
                        this.order.push(sortObj[this.source[sortIndex].data[itemIndex].id])
                        this.source[sortIndex].data[itemIndex].is_selected = 1
                        animation = 2
                    }
                }
                // 动画
                this.source[sortIndex].data[itemIndex].animation = animation
                clearTimeout(timer)
                timer = null
                timer = setTimeout(() => {
                    this.source[sortIndex].data[itemIndex].animation = 0
                }, 500)
            },
            // PC端删除已订阅
            deleteOrder(index, id) {
                this.order.splice(index, 1)
                // 从所有平台找出被删除的订阅
                for (let i = 0; i < this.source.length; i++) {
                    for (let k = 0; k < this.source[i].data.length; k++) {
                        if (id == this.source[i].data[k].id) {
                            this.source[i].data[k].is_selected = 0
                            return undefined
                        }
                    }
                }
            },
            // 获取订阅列表
            getList() {
                let that = this
                that.$({
                    url: '/hot/source',
                    success(res) {
                        let source = res.data.source
                        // 数据赋值渲染
                        for (let i = 0; i < source.length; i++) {
                            for (let k = 0; k < source[i].data.length; k++) {
                                source[i].data[k].animation = 0
                            }
                        }
                        that.source = JSON.parse(JSON.stringify(source))

                        // 扁平化所有平台用来匹配
                        for (let i = 0; i < source.length; i++) {
                            for (let k = 0; k < source[i].data.length; k++) {
                                sortObj[source[i].data[k].id] = source[i].data[k]
                            }
                        }

                        // 平台排序
                        let order = res.data.order.split(',')
                        let orderArr = [];
                        for (let i = 0; i < order.length; i++) {
                            sortObj[order[i]] && orderArr.push(sortObj[order[i]])
                        }
                        that.order = orderArr
                    },
                    error(res) {
                        if (res.status && res.status == 100401) {
                            that.$router.replace({
                                path: '/login'
                            })
                            that.$toast('需要登录')
                        } else {
                            that.$toast('获取分类列表失败', 'error')
                        }
                    }
                })
            },
            // 保存订阅列表
            save() {
                let that = this
                if (that.order.length > 0) {
                    var ajaxData = that.order.map(i => i.id).join(',');
                    that.$({
                        url: '/user/info',
                        data: {
                            subscribe_source: ajaxData
                        },
                        type: 'POST',
                        success(res) {
                            that.$toast(res.message, 'success')
                            that.$router.push({
                                path: '/'
                            })
                        },
                        error(res) {
                            that.$toast(res.message, 'error')
                        }
                    })
                } else {
                    that.$toast('至少选择一个订阅', 'error')
                }
            }
        },
        created() {
            this.getList()
        },
        mounted() {
            let that = this
            // 检测宽度变化
            that.isPC = window.innerWidth <= 768 ? false : true
            window.onresize = () => {
                that.isPC = window.innerWidth <= 768 ? false : true
            }
        },
        beforeDestroy() {
            window.onresize = null
        }
    }
</script>

<style scoped lang="less">
    .mysubscribe {
        min-height: calc(100% - 180px);
        padding: 20px;
    }

    .mobile {
        display: none;
    }

    .title {
        padding-bottom: 20px;
        font-size: 1.2rem;

        >.icon-close {
            float: right;
            padding: 2px;
            font-size: 24px;
            cursor: pointer;
        }
    }

    // 移动端兼容
    @media screen and (max-width: 768px) {
        .title>.icon-close {
            display: none;
        }
    }

    .source-box {
        padding-bottom: 10px;

        >p {
            padding: 10px 0;
            margin-bottom: 10px;
            font-size: 0.9rem;
            color: #666;
        }

        .source-item {
            display: inline-block;
            color: #999;
            background-color: #fff;
            padding: 10px;
            margin: 0 10px 10px 0;
            font-size: 14px;
            border-radius: 4px;
            cursor: pointer;
            transition: color 0.3s, background-color 0.3s;
            animation-duration: .5s;
            animation-fill-mode: both;
        }

        .source-item-selected {
            color: #fff;
            background-color: #4bab5e;
        }

        .source-item-animation-1 {
            animation-name: shakeX;
        }

        .source-item-animation-2 {
            animation-name: shakeY;
        }

        @keyframes shakeX {

            0%,
            to {
                -webkit-transform: translateZ(0);
                transform: translateZ(0)
            }

            20%,
            60% {
                -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0)
            }

            40%,
            80% {
                -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0)
            }
        }

        @keyframes shakeY {

            0%,
            to {
                -webkit-transform: translateZ(0);
                transform: translateZ(0)
            }

            20%,
            60% {
                -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0)
            }

            40%,
            80% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
            }
        }

        // 动画
        .list-transition-item {
            transition: transform .4s;
        }

        .list-transition-enter,
        .list-transition-leave-to {
            opacity: 0;
            transform: translateX(30px);
        }

        .list-transition-leave-active {
            position: absolute;
        }
    }

    // PC端已订阅
    .source-box-selected {
        padding-bottom: 30px;
        margin-bottom: 20px;

        .source-item {
            cursor: move;

            >span {
                vertical-align: text-top;
                margin-right: 5px;
            }

            >i {
                cursor: pointer;
                padding: 5px;
            }
        }
    }

    .btn-box {
        text-align: center;
        padding-top: 40px;

        >.btn {
            padding: 8px 40px;
        }
    }

    .theme-1 {
        .source-item {
            background-color: #1f2025;
        }

        .source-item-selected {
            color: #fff;
            background-color: #4bab5e;
        }
    }
</style>